<template>
  <div>
      <!-- 顶部工具条 -->
      <el-row>
          <el-col>
              <el-form :inline='true'>
                  <el-form-item label='关键字:'>
                      <el-input v-model='filters.condition' placeholder='考生姓名/手机' clearable></el-input>
                  </el-form-item>
                  <el-form-item label='单位名称:'>
                      <el-input v-model='filters.unitName' placeholder='请输入单位名称' clearable></el-input>
                  </el-form-item>
                  <el-form-item label='信用代码:'>
                      <el-input v-model='filters.creditCode' placeholder='请输入信用代码' clearable></el-input>
                  </el-form-item>
                  <el-form-item>
                      <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                  </el-form-item>
              </el-form>                           
          </el-col>         
      </el-row>
      <!-- 表格主体 -->
      <el-table
        v-loading='listLoading'
        :data="tableData"
        border
        style="width: 95%"
        highlight-current-row
        @current-change='currentChange'
        :cell-style='rowClass'
        :header-cell-style='headerStyle'
        >   
            <el-table-column type='index' label='序号' width='60'></el-table-column>
            <el-table-column prop="Name" label="名称"></el-table-column>
            <el-table-column prop="PhoneNumber" label="手机"></el-table-column>
            <el-table-column prop="UnitName" label="单位名称"></el-table-column>
            <el-table-column prop="City" label="市"></el-table-column>
            <el-table-column prop="Region" label="区/县"></el-table-column>
            <!-- <el-table-column prop="StudentExamineeIdentityID" label="身份证号"></el-table-column> -->
            <el-table-column prop="StudentExamineeIdentityID" label="是否存在档案">
                <template slot-scope='scope'>
                    <el-tag type='success' v-if='scope.row.StudentExamineeIdentityID >= 0'>是</el-tag>
                    <el-tag type='danger' v-else>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="RequestSource" label="注册来源"></el-table-column>
            <el-table-column prop="IsEnable" label="账号状态">
                <template slot-scope='scope'>
                    <el-tag type='success' v-if='scope.row.IsEnable == true'>启用</el-tag>
                    <el-tag type='danger' v-else>禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='CreateTime' label='创建时间'></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.PageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.DataCount"
        class='pageBar'>
      </el-pagination>   
  </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs';
import util from "../../../util/date";
import {getLearnStudentInfoListPage,enableOrDisableStudentExamineeAccount} from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            filters:{
                condition:'',
                unitName: '',
                creditCode: '',
            },
            tableData:[],
            currentRow:{},
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            status:null,
            buttonList:[],
            listLoading:false,
            ProvinceID: 1919,
            CityID: '',
            RegionID: '',
            TownID: '',
            isLevel: '',
        }
    },
    methods:{
        // 列表样式
        rowClass(){
            return 'text-align:center;'
        },
        headerStyle(){
            return 'text-align:center;'
        },
        // 筛选框清空重新请求数据
        clearContent(){
            this.getData()
        },
        // 页面数据
        getData(){
            this.listLoading = true
            var params = {
                keyWord:this.filters.condition,
                socialUnitName:this.filters.unitName ? this.filters.unitName : null,
                socialCreditNumber:this.filters.creditCode ? this.filters.creditCode : null,
                provinceID: this.ProvinceID,
                cityID: this.CityID,
                regionID: this.RegionID,
                townID: this.TownID,
                isLevel: this.isLevel ? this.isLevel : "",
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }
            getLearnStudentInfoListPage(params).then(res => {
                if(res.data.Success){
                    this.listLoading = false
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                    this.currentRow = {}
                }else{
                    this.listLoading = false
                    this.$message.error(res.data.Message)
                }
            })
        },
        // 获取当前行数据
        currentChange(row){
            if(JSON.stringify(row) == '{}'){
                this.$message.error('请选择一行数据')
            }else{
                this.currentRow = row
            }         
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        // 查询事件
        getTableDataFun(){
            this.pages.PageIndex = 1
            this.getData()
        },
        // 启用/禁用事件
        handleEnableOrDisable(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}' || row == null){
                return this.$message.error('请选择一行数据');
            }
            this.$confirm('确定要启用/禁用此账号吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(res => {
                var params = {
                    Id:this.currentRow.Id,
                    IsEnabled:this.currentRow.IsEnable == true ? false :true
                }
                enableOrDisableStudentExamineeAccount(params).then(res => {
                    if(res.data.Success){
                        this.$message({
                            message: res.data.Message,
                            type: 'success'
                        });
                        this.filters.condition = ''
                        this.filters.unitName = ''
                        this.filters.creditCode = ''
                        this.getData()
                        this.currentRow = {}
                    }else{
                        this.$message({
                            message: res.data.Message,
                            type: 'error'
                        });
                    }
                })
            }).catch(() => {})           
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 时间格式化
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    created(){
        this.ProvinceID = this.$route.query.ProvinceID
        this.CityID = this.$route.query.CityID
        this.RegionID = this.$route.query.RegionID
        this.TownID = this.$route.query.TownID
        this.isLevel = this.$route.query.isLevel
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
</style>